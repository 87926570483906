import React from "react"
import AddSimplePosition from "../modules/AddSimplePosition"
import Modal from '../../../Modal/operationModal';
import ModalQRCheck from "../../../Modal/qrCodeModalPrecheck";

const SaleSimpleContainer = ({context, history}) => {
  const cid = JSON.parse(localStorage.getItem("C_ID"));
  const kkm = JSON.parse(localStorage.getItem("KKM"));
  const main = {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center"
  }
  return (
    <div style={main}>
      <AddSimplePosition context={context} status={'sale'} history={history} />
      <Modal
        onClose={context.closeModal}
        onCheck={context.showCheck}
        onElectronCheck={context.electronShowCheck}
        show={context.Modal.Status}
        header={context.Modal.Header}
        body={context.Modal.Body}
        img={context.CheckImg}
        docId={context.CheckId}
        docLink={context.CheckLink}
        share={context.shareSocial}
      />
      <ModalQRCheck
        onClose={context.closeModalQRCheck}
        onSuccess={context.closeModalOnSuccess}
        onCheck={context.showQRPrecheck}
        show={context.ModalQRCheck.Status}
        companyName={kkm !== null && kkm.Company.FullName}
        bin={kkm !== null && kkm.Company.Bin}
        total={context.Total}
        cid={cid}
      />
    </div>
  )
}

export default SaleSimpleContainer;
