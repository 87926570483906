import React from "react"
import AddPosition from "../modules/AddPosition"
import MobileAddPosition from "../modules/MobileAddPosition"
import PreCheck from "../modules/PreCheck"
import MobilePreCheck from "../modules/MobilePreCheck"
import Pays from "../modules/Pays"
import Modal from '../../../Modal/operationModal';
import ModalQRCheck from "../../../Modal/qrCodeModalPrecheck";
import style from "../../styles/pages/container.module.scss"
import Error503Modal from "../../Modals/Error500Modal"

const SaleContainer = ({context, history}) => {
  const cid = JSON.parse(localStorage.getItem("C_ID"));
  const kkm = JSON.parse(localStorage.getItem("KKM"));

  return (
    <div className={style.main}>
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <AddPosition context={context} status={'sale'} show={true} />
        ) : (
          <MobileAddPosition context={context} status={'sale'} show={true} />
        )
      }
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <PreCheck context={context} />
        ) : (
          <MobilePreCheck context={context} />
        )
      }
      <Pays
        context={context}
        history={history}
        status={'sale'}
        showPayTypes={true}
        showChanges={true}
        showPlus={true}
        adaptive={!window.matchMedia("(min-width: 1100px)").matches && true}
      />
      {
        context.Modal.Header === '503 ошибка' ? (
          <Error503Modal />
        ) : (
          <Modal
            onClose={context.closeModal}
            onCheck={context.showCheck}
            onElectronCheck={context.electronShowCheck}
            show={context.Modal.Status}
            header={context.Modal.Header}
            body={context.Modal.Body}
            img={context.CheckImg}
            docId={context.CheckId}
            docLink={context.CheckLink}
            share={context.shareSocial}
          />
        )
      }
      <ModalQRCheck
        onClose={context.closeModalQRCheck}
        onSuccess={context.closeModalOnSuccess}
        onCheck={context.showQRPrecheck}
        show={context.ModalQRCheck.Status}
        companyName={kkm !== null && kkm.Company.FullName}
        bin={kkm !== null && kkm.Company.Bin}
        total={context.Total}
        cid={cid}
      />
    </div>
  )
}

export default SaleContainer;
