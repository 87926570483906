import React from "react";
import style from './Footer.module.scss'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation()

    return <footer className={`${style.footer} ${style.new}`}>
        <p>{ t('auth.online_cash_register_from_kassa24_business') }<span>© 2021</span></p>
    </footer>
}

export default Footer;
