/* eslint-disable eqeqeq */
import React from "react"
import { useTranslation } from "react-i18next";

import style from "../../styles/pages/preCheck.module.scss"
import { ReactComponent as Storno } from "../images/storno.svg"
import { ReactComponent as Return } from "../images/return.svg"
import { DISCOUNT_TYPES, OPERATION_TYPES } from "../../../../constants/app";

const PreCheckLength = ({ length, id }) => {
  const { t } = useTranslation()

  if (id) {
    if (length === 1) {
      return `${length} ${t('receipt.item')}`
    } else if (length > 1) {
      let a = length
      let x = "" + a
      x = x.split("")[x.length - 1]
      if (x == 1) {
        return `${length} ${t('receipt.item')}`
      } else {
        return `${length} ${t('receipt.items')}`
      }
    }
  } else {
    return `0 ${t('receipt.items')}`
  }
}

const CostFormat = (value) => {
  if (value.toString().length) {
    let str = value.toString().split('.')
    let str1 = str[0]
    let str2 = str[1]

    str1 = str1.split("").reverse().join("");
    str1 = str1.match(/.{1,3}/g)
    str1 = str1.join(" ")
    str1 = str1.split("").reverse().join("");
    return `${str1}.${str2 || '00'}`
  } else {
    return value
  }
}

const PreCheck = ({ context, operationType }) => {
  const { t } = useTranslation()
  return (
    <div className={style.preCheck}>
      <div className={style.header}>
        <p className={style.title}>
          { t('receipt.precheck') }
          <span><PreCheckLength length={context.NewPreCheck.length} id={context.NewPreCheck[0].id} /></span>
        </p>
        {
          context.NewPreCheck.length > 1 || context.NewPreCheck[0].id !== 0 ? (
            <p className={style.clearActive} onClick={() => {
              context.resetNewPreCheck()
              context.resetPosition()
            }}>{ t('receipt.reset_precheck') }</p>
          ) : (
            <p className={style.clear}>{ t('receipt.reset_precheck') }</p>
          )
        }
      </div>
      <div className={style.body}>
        {
          context.NewPreCheck.length > 1 || context.NewPreCheck[0].id !== 0 ? (
            context.NewPreCheck.map((item, index) => (
              item.id ? (
                <div className={style.position} key={item.id} onClick={() => console.log(item)}>
                  <div className={style.head}>
                    <div className={style.main}>
                      <p className={item.Position.Storno ? 'storned' : ''} style={{minHeight: '20px'}}>{item.Position.Name}</p>
                      <p className={item.Position.Storno ? 'storned' : ''}>{CostFormat(item.Position.Price)} ₸ x {item.Position.Qty} {item.Position.Unit.ShortName}. </p>
                    </div>
                    <div className={style.right}>
                      <div className={`${style.price}${item.Position.Storno ? ' storned' : ''}`}>
                        {CostFormat(item.Position.Total)} ₸
                      </div>
                      <button className={style.storno} onClick={() => context.newStornoToggle(index)}>
                        {
                          item.Position.Storno ? (
                            <Return />
                          ) : (
                            <Storno />
                          )
                        }
                      </button>
                    </div>
                  </div>
                  <div className={style.foot}>
                    <div className={style.percents}>
                      <p>
                        { t('receipt.discount_alt') }{" "}
                        {item.Position.DiscountPercent}{item.Position.DiscountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸'} = {item.Position.Discount} ₸
                      </p>
                    </div>
                    <div className={style.percents}>
                    <p>
                        { t('receipt.markup_alt') }{" "}
                        {item.Position.MarkupPercent}{item.Position.DiscountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸'} = {item.Position.Markup} ₸
                      </p>
                      <div className={style.bullet}></div>
                      <p>{item.Position.Section.Name}</p>
                    </div>
                  </div>
                </div>
              ) : null
            ))
          ) : (
            <div className={style.empty} key={0}>
              <p>{ t('other.empty_precheck') }</p>
              <p>
                {(operationType === OPERATION_TYPES.REFUND.KEY || operationType === OPERATION_TYPES.PURCHASE_REFUND.KEY) 
                  ? t('other.precheck_refund_description') 
                  : t('other.add_items_on_the_left_block')
                }
              </p>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PreCheck;
