/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react"
import style from "../../styles/pages/preCheck.module.scss"
import { ReactComponent as Storno } from "../images/storno.svg"
import { ReactComponent as Return } from "../images/return.svg"
import { useTranslation } from "react-i18next";
import { DISCOUNT_TYPES, OPERATION_TYPES } from "../../../../constants/app";

const PreCheckLength = ({ length }) => {
  const { t } = useTranslation()

  if (!length)  {
    return `0 ${ t('receipt.items') }`
  }
  if (length === 1) {
    return `${length} ${ t('receipt.item') }`
  } else if (length > 1) {
    let a = length
    let x = "" + a
    x = x.split("")[x.length - 1]
    if (x == 1) {
      return `${length} ${ t('receipt.item') }`
    } else {
      return `${length} ${ t('receipt.items') }`
    }
  }
}

const PreCheck = ({ context, operationType }) => {
  const { t } = useTranslation()

  const [refundPositionDetailsIsAvailable, setRefundPositionDetailsIsAvailable] = useState(false)
  useEffect(() => {
    if (!localStorage.getItem('PositionDetailsFromReport')) {
      return
    }
    setRefundPositionDetailsIsAvailable(true)
    context.getRefundPositionDetails()
    return () => {
      localStorage.removeItem('PositionDetailsFromReport')
      localStorage.removeItem('OperationIdFromReport')
      localStorage.removeItem('PositionDetailsIsCashTransaction')
    }
  }, [])

  return (
    <div className={style.preCheck}>
      <div className={style.header}>
        <p className={style.title}>
          { t('receipt.precheck') }
          <span>
            {
              refundPositionDetailsIsAvailable
                ?
                <PreCheckLength length={context.NewPreCheck.length} />
                :
                <PreCheckLength length={context.PreCheck.length} />
            }
          </span>
        </p>
        {
          refundPositionDetailsIsAvailable
            ?
            (context.NewPreCheck.length > 1 || context.NewPreCheck[0].id !== 0 ? (
              <p className={style.clearActive} onClick={() => {
                context.resetNewPreCheck()
                context.resetPosition()
              }}>{ t('receipt.reset_precheck') }</p>
            ) : (
              <p className={style.clear}>{ t('receipt.reset_precheck') }</p>
            ))
            :
            (context.PreCheck.length > 0 ? (
              <p className={style.clearActive} onClick={() => context.resetPreCheck()}>{ t('receipt.reset_precheck') }</p>
            ) : (
              <p className={style.clear}>{ t('receipt.reset_precheck') }</p>
            ))
        }
      </div>
      <div className={style.body}>
        {
          refundPositionDetailsIsAvailable
            ?
            (context.NewPreCheck.length > 0 ? (
              context.NewPreCheck.map((item, index) => (
                <div className={style.position} key={item.IdUnit}>
                  <div className={style.head}>
                    <div className={style.main}>
                      <p className={item.Position.Storno ? 'storned' : ''} style={{minHeight: '20px'}}>{item.Position.Name}</p>
                      <p className={item.Position.Storno ? 'storned' : ''}>{item.Price} ₸ x {item.Qty} {item.Position.Unit.ShortName}. </p>
                    </div>
                    <div className={style.right}>
                      <div className={`${style.price}${item.Position.Storno ? ' storned' : ''}`}>
                        {item.Position.Total} ₸
                      </div>
                      <button className={style.storno} onClick={() => context.stornoToggle(index)}>
                        {
                          item.Position.Storno ? (
                            <Return />
                          ) : (
                            <Storno />
                          )
                        }
                      </button>
                    </div>
                  </div>
                  <div className={style.foot}>
                    <div className={style.percents}>
                      <p>
                        { t('receipt.discount_alt') }{" "}
                        {item.Position.DiscountPercent}{item.Position.DiscountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸'} = {item.Position.Discount} ₸
                      </p>
                    </div>
                    <div className={style.percents}>
                      <p>
                        { t('receipt.markup_alt') }{" "}
                        {item.Position.MarkupPercent}{item.Position.DiscountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸'} = {item.Position.Markup} ₸
                      </p>
                      <div className={style.bullet}></div>
                      <p>{item.Position.Section.Name}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={style.empty}>
                <p>{ t('other.empty_precheck') }</p>
                <p>{(operationType === OPERATION_TYPES.REFUND.KEY || operationType === OPERATION_TYPES.PURCHASE_REFUND.KEY) 
                  ? t('other.precheck_refund_description') 
                  : t('other.add_items_on_the_left_block')
                }</p>
              </div>
            ))
              :
          (context.PreCheck.length > 0 ? (
            context.PreCheck.map((item, index) => (
              <div className={style.position} key={item.IdUnit}>
                <div className={style.head}>
                  <div className={style.main}>
                    <p className={item.Storno ? 'storned' : ''} style={{minHeight: '20px'}}>{item.Name}</p>
                    <p className={item.Storno ? 'storned' : ''}>{item.Price} ₸ x {item.Qty} {item.Unit.ShortName}. </p>
                  </div>
                  <div className={style.right}>
                    <div className={`${style.price}${item.Storno ? ' storned' : ''}`}>
                      {item.Total} ₸
                    </div>
                    <button className={style.storno} onClick={() => context.stornoToggle(index)}>
                      {
                        item.Storno ? (
                          <Return />
                        ) : (
                          <Storno />
                        )
                      }
                    </button>
                  </div>
                </div>
                <div className={style.foot}>
                  <div className={style.percents}>
                    <p>{ t('receipt.discount_alt') } {item.DiscountPercent}% = {item.Discount} ₸</p>
                  </div>
                  <div className={style.percents}>
                    <p>{ t('receipt.markup_alt') } {item.MarkupPercent}% = {item.Markup} ₸</p>
                    <div className={style.bullet}></div>
                    <p>{item.Section.Name}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={style.empty}>
              <p>{ t('other.empty_precheck') }</p>
              <p>{ t('other.add_items_on_the_left_block') }</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default PreCheck;
