exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".check_main__3gHDQ {\n  margin-top: 20px;\n  padding-bottom: 20px;\n  display: flex;\n  justify-content: center; }\n\n@media only screen and (max-width: 415px) {\n  .check_main__3gHDQ {\n    flex-direction: column;\n    align-items: center;\n    padding-bottom: 70px; } }\n", ""]);

// exports
exports.locals = {
	"main": "check_main__3gHDQ"
};