import React from "react"
import AddPosition from "../modules/AddPosition"
import MobileAddPosition from "../modules/MobileAddPosition"
import PreCheck from "../modules/PreCheck"
import MobilePreCheck from "../modules/MobilePreCheck"
import Pays from "../modules/Pays"
import Modal from '../../../Modal/operationModal';
import style from "../../styles/pages/container.module.scss"
import Error503Modal from "../../Modals/Error500Modal"

const PurchaseContainer = ({context, history}) => {
  return (
    <div className={style.main}>
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <AddPosition context={context} status={'purchase'} />
        ) : (
          <MobileAddPosition context={context} status={'purchase'} />
        )
      }
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <PreCheck context={context} />
        ) : (
          <MobilePreCheck context={context} />
        )
      }
      <Pays
        context={context}
        history={history}
        status={'purchase'}
        iinTitle={'продавца'}
        adaptive={!window.matchMedia("(min-width: 1100px)").matches && true}
      />
      {
        context.Modal.Header === '503 ошибка' ? (
          <Error503Modal />
        ) : (
          <Modal
            onClose={context.closeModal}
            onCheck={context.showCheck}
            onElectronCheck={context.electronShowCheck}
            show={context.Modal.Status}
            header={context.Modal.Header}
            body={context.Modal.Body}
            img={context.CheckImg}
            docId={context.CheckId}
            docLink={context.CheckLink}
            share={context.shareSocial}
          />
        )
      }
    </div>
  )
}

export default PurchaseContainer;
